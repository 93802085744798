<template>
  <div id="page">
    <Head :headTitle="headTitle" />
    <div class="container" id="map_container">
      <!-- <div class="con-1">
        <div class="mod2">
          <div class="item item1">
            <div class="s-pub-tk">
              <span class="bt">公共交通概况</span>
            </div>
          </div>
          <div class="item item2">
            <div class="txt">
              <p>
                <span class="tit">高速总长</span>
                <em>{{ publicTransportProfile.expressway }}</em>
                <span class="tit">km</span>
              </p>
            </div>
          </div>
          <div class="item item3">
            <div class="txt">
              <p>
                <span class="tit">高铁站总数</span>
                <em>{{ publicTransportProfile.highSpeedRailStation }}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
          <div class="item item4">
            <div class="txt">
              <p>
                <span class="tit">机场总数</span>
                <em>{{ publicTransportProfile.airportTotal }}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
          <div class="item item5">
            <div class="txt">
              <p>
                <span class="tit">交通接驳站数量</span>
                <em>{{ publicTransportProfile.trafficDockingDtation }}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
          <div class="item item6">
            <div class="txt">
              <p>
                <span class="tit">公交站数量</span>
                <em>{{ publicTransportProfile.busStop }}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
          <div class="item item7">
            <div class="txt">
              <p>
                <span class="tit">汽车站数量</span>
                <em>{{ publicTransportProfile.motorStation }}</em>
                <span class="tit">个</span>
              </p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div id="tip" class="info" style="min-width: 18rem"></div>
  </div>
</template>
  
  <script>
import Head from "@/components/head_sm.vue";
import AMapLoader from '@amap/amap-jsapi-loader';
import axios from "axios";
export default {
  data() {
    return {
      headTitle: "",
      publicTransportProfile: {},
      stationKeyWord: "新亨镇"
    };
  },
  components: {
    Head,
  },
  mounted() {
    AMapLoader.reset()
    window._AMapSecurityConfig = {
      securityJsCode: "9ae48878100f76d5ba48af912f8094ed", // 申请key对应的秘钥
    };
    this.initMap();
    this.getData();
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "b00d106d860bfe0027c0545da7136743", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.StationSearch",
          "AMap.Driving",
          "AMap.TileLayer.Traffic",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          const map = new AMap.Map("map_container", {
            //设置地图容器id
            // pitch:75,
            viewMode: "3D", //是否为3D地图模式
            zoom:20.8, //初始化地图级别 15.8
            center: [116.289995, 23.625457], //初始化地图中心点位置
            mapStyle: "amap://styles/darkblue",
          });
          const marker = new AMap.Marker({
            position: new AMap.LngLat(116.289995, 23.625457), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "新亨",
          });
          map.add(marker);
          const driving = new AMap.Driving({
            map: map,
            // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
            // eslint-disable-next-line no-undef
            policy: AMap.DrivingPolicy.LEAST_TIME,
          });
          const points = [
            { keyword: "新亨镇人民政府", city: "新亨镇" },
            { keyword: "揭阳北站", city: "新亨镇" },
          ];
          driving.search(points, (status, result) => {
            // 未出错时，result即是对应的路线规划方案
            console.log("status=", status);
            console.log("result=", result);
          });
          var trafficLayer = new AMap.TileLayer.Traffic({
            autoRefresh: true, // 是否自动刷新，默认为false
            interval: 180, // 刷新间隔，默认180s
            zIndex: 10,
            zooms: [15, 22],
          });
          map.add(trafficLayer);
          var marker2 = new AMap.Marker({
                icon: new AMap.Icon({
                    image:
                      "//a.amap.com/jsapi_demos/static/resource/img/pin.png",
                    size: new AMap.Size(32, 32),
                    imageSize: new AMap.Size(32, 32),
                  }),
                map: map,
                position: [116.29157199999997, 23.621173],
                label: {
                  offset: new AMap.Pixel(5, 5), //修改label相对于maker的位置
                  content: "新亨车站(公交站)",
                },
              });
              map.add(marker2);
              var marker4 = new AMap.Marker({
                icon: new AMap.Icon({
                    image:
                      "//a.amap.com/jsapi_demos/static/resource/img/pin.png",
                    size: new AMap.Size(32, 32),
                    imageSize: new AMap.Size(32, 32),
                  }),
                map: map,
                position: [116.28943600000002, 23.623451],
                label: {
                  offset: new AMap.Pixel(5, 5), //修改label相对于maker的位置
                  content: "新亨镇政府车站(公交站)",
                },
              });
              map.add(marker4);
              var marker3 = new AMap.Marker({
                icon: new AMap.Icon({
                    image:
                      "//a.amap.com/jsapi_demos/static/resource/img/pin.png",
                    size: new AMap.Size(32, 32),
                    imageSize: new AMap.Size(32, 32),
                  }),
                map: map,
                position: [116.25398999999999, 23.62764],
                label: {
                  offset: new AMap.Pixel(5, 5), //修改label相对于maker的位置
                  content: "新亨北良车站(公交站)",
                },
              });
              map.add(marker3);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getData() {
      axios
        .get("/10zhjt.json",{
          params:{
            // timestamp:new Date().getTime()
            random:Math.random()
          }
        })
        .then((res) => {
          // 处理响应
          // console.log("数据:"+res.data);
          let dataObject = res.data; // 这里假设获取到的数据是一个 Object 类型的数据
          // console.log(dataObject.data);
          //
          let jsonString = JSON.stringify(dataObject); // 将 Object 类型的数据转换为字符串类型的 JSON 数据
          // console.log(jsonString) // 输出字符串类型的 JSON 数据
          let jsonObject = JSON.parse(jsonString);

          // this.publicTransportProfile = jsonObject;
          //
          // this.schoolTotal = jsonObject.schoolTotal;
          //标题
          this.headTitle = jsonObject.bigTitle;
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    }
  },
};
</script>
  
  <style scoped>
#page {
  width: 100%;
  height: 2180px;
  /* background: url(https://home/ResImg/zhihuijiaotong.png) no-repeat center;
  background-size: 100% 100%; */
  background-color: #0a224b;
}
#map_container{
  padding: 0px;
  margin: 0px;
  width: 100%;
}
.mod2 {
  height: 50%;
  width: 50%;
}
.mod3 {
  height: 30%;
  margin-bottom: 10px;
}

.mod2 .box {
  position: absolute;
  left: 28%;
  top: 12%;
  width: 1671px;
  height: 1522.35px;
  opacity: 1;
}
.mod2 .item {
  width: 350px;
  padding: 1px;
  border-radius: 10px;
  background: rgba(0, 48, 104, 0.55);
  opacity: 1;
  box-sizing: border-box;
  border: 5px solid;
  border-image: linear-gradient(157deg, #58a1ff 30%, #01407d 74%) 5;

  backdrop-filter: blur(20px);
  position: absolute;
}
.mod2 .item .txt {
  font-size: 52px;
  padding: 10px;
  font-weight: normal;
  letter-spacing: 10.4px;
  color: #45f5ff;
}
.mod2 .item .txt p {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.mod2 .item .tit {
  font-family: Source Han Sans CN;
  font-size: 45px;
  font-weight: 500;
  letter-spacing: 0px;

  color: #ffffff;
}

.mod2 .item .txt * {
  font-family: "YouSheBiaoTiHei";
}

.mod2 .item1 {
  left: 2%;
  bottom: 33%;
  width: 1016px;
  height: 69.9px;
  position: absolute;
  opacity: 1;

  background: rgba(0, 48, 104, 0.55);

  backdrop-filter: blur(20px);
}
.mod2 .item2 {
  left: 2%;
  bottom: 25%;
  width: 503px;
  height: 120px;
}
.mod2 .item3 {
  left: 16%;
  bottom: 25%;
  width: 503px;
  height: 120px;
}
.mod2 .item4 {
  left: 2%;
  bottom: 15%;
  width: 503px;
  height: 120px;
}
.mod2 .item5 {
  left: 16%;
  bottom: 15%;
  width: 503px;
  height: 120px;
}
.mod2 .item6 {
  left: 2%;
  bottom: 5%;
  width: 503px;
  height: 120px;
}
.mod2 .item7 {
  left: 16%;
  bottom: 5%;
  width: 503px;
  height: 120px;
}

.s-pub-tk {
  background: url(../assets/images/wt26.png) no-repeat left center #d1d3d600;
  background-size: auto 100%;
  padding-left: 100px;
  padding-right: 1px;
  align-items: center;
  height: 70px;
  bottom: 20px;
}
.s-pub-tk .bt {
  font-family: Source Han Sans CN;
  font-size: 46px;
  font-weight: normal;

  letter-spacing: 9.2px;

  color: #ffffff;
}

.con-1 {
  width: 100%;
  height: 100%;
}
/deep/.amap-icon img {
  position: relative;
}
/deep/.amap-info-content {
  position: relative;
  background: #fff;
  padding: 5px;
  line-height: 1;
  overflow: auto;
}
/deep/.amap-info-close {
  opacity: 0;
}
</style>
  